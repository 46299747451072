@use "sass:math";

@import '../../scss/index.scss';

.main-banner {
  position: relative;
  min-height: 100vh;
  background-image: url('../../assets/img/portada.jpeg');
  @media (max-width: $media-break-point-down-md) {
    background-image: url('../../assets/img/portada.jpeg');
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &__dark {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000; 
    opacity: 0.4;
  }
  &__logo {
    width: 100%;
    height: 120px;
    @media (min-width: $media-break-point-down-xl) {
     margin-top: 5%;
     height: 160px;
    }
  
    @media (max-width: $media-break-point-down-md) {
      margin-top: 10%;
      margin-bottom: 1%;
    }

    img {
    width: 100%;
    height: 100%;
    object-fit: contain;  
   
    }     
}



  .ant-row {
    display: flex;
    align-items: center;
    height: 100%;



    h2 {
      color: #fff;
      font-size: 0em + math.div(60px, $default-font-size);
      font-weight: bold;
      line-height: 1;
      margin-top: 5px;
      @media (max-width: $media-break-point-down-md) {
        font-size: 0em + math.div(30px, $default-font-size);
        text-align: center;
        margin: 5px;
        top: 0;
      }

    }

    h3 {
      color: #fff;
      font-size: 0em + math.div(20px, $default-font-size);
      @media (max-width: $media-break-point-down-md) {
        font-size: 0em + math.div(15px, $default-font-size);
        text-align: center;
        margin: 5px;
      }
    }
  }
}

.sign-in {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;


  &-content {
    display: flex;
    @media (max-width: $media-break-point-up-lg) {
      flex-direction: column;
    }
    align-items: center;
    min-height: 100vh;
    width: 100%;
    padding: 5%;
    &__logo {
      @media (max-width: $media-break-point-down-xs) {
        width: 100%;
      }
      width: 50%;
      margin: 2%;
      text-align: center;
      img {
        width: 100%;
      }
    }

    &__tabs {
      background-color: $background-light;
      margin: 2%;

      @media (min-width: $media-break-point-up-md) {
        width: 100%;
      }
      @media (max-width: $media-break-point-down-md) {
        width: 80%;
        margin-left: 10%;;
        margin-right: 10%;
      }
   
      .ant-tabs {
        @media (min-width: $media-break-point-up-lg) {
          width: auto;
        }

        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            display: flex;
            width: 100%;
            height: 60px !important;
            text-align: center;
            font-size: 0em + math.div(19px, $default-font-size);
            border-top: 4px solid $border-grey !important;
            border-radius: 0 !important;
            margin: 0 !important;
            @media (min-width: $media-break-point-up-lg) {
              width: 220px;
              font-size: 0em + math.div(23px, $default-font-size);
            }

            &:hover {
              color: $font-grey-dark;
            }
            &:before {
              content: none;
            }
            span {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .ant-tabs-content-holder {
          padding: 10px;
          text-align: center;
        }
      }
    }
  }
}
