@import '../../../scss/index.scss';

.tools {
  &__firstHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
    .ant-card-body {
      padding: 10px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    .ant-btn {
      margin-right: 10px;
    }
  }

  &__table {
    background-color: #fff;
    padding: 20px 20px;
  }
}
