.form {
    text-align: initial;
    .ant-form-item {
      .ant-col {
        .ant-input {
          border-radius: 4px;
          .ant-input-affix-wrapper {
            border-radius: 4px;
          }
        }
        .ant-select {
          .ant-select-selector {
            border-radius: 4px;
          }
          display: flex;
          border-radius: 2;
        }
      }
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .button-submit {
      margin-top: 10px;
      width: 100%;
    }
  }
  