@import '../../scss/index.scss';

.card {
    .ant-card-head {
        text-align: center;
        background-color: $second-button-color;
        color: $font-light;
    .ant-card-extra{
        button {
            color: $font-light;

        }
    }
    }
}