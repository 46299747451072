.view-material-table {
  height: 780px;
  width: 605px;
  position: relative;
  margin: 0 auto;
  .view-material {
    font-size: 1.2em;
    margin: 10px;
    &__title {
      display: flex;
      justify-content: space-between;
      vertical-align: middle;
      img {
        width: 80px;
        height: 30px;
        margin-top: 10px;
        margin-right: 15px;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0px;
    }
  }
}
