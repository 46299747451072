.ant-legacy-form {
  .ant-legacy-form-item {
    .ant-col {
      .ant-input {
        border-radius: 4px;
        .ant-input-affix-wrapper {
          border-radius: 4px;
        }
      }
      .ant-select {
        .ant-select-selector {
          border-radius: 4px;
        }
        display: flex;
        border-radius: 2;
      }
      flex: auto;
    }
    margin-top: 5px;
    margin-bottom: 5px;
  }

  i {
    color: rgba(0, 0, 0, 0.25);
  }
  .button-submit {
    width: 100%;
  }
}
