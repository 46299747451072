
.header { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 2%;

    &__title {
      margin-left: 5px;
      font-weight: bold;
      font-size: 16px;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

    }
}