@import '../../../scss/index.scss';

.works {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;

      .ant-btn {
        margin-right: 10px;
      }
    }
    &-switch {
      display: flex;
      align-items: center;

      > button {
        margin-right: 20px;
      }
    }
  }

  &__list {
    background-color: #fff;
    padding: 20px 20px;
  }
}
