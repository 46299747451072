@import '../../scss/index.scss';

.view-work { 
    &__header {
        background-color: #fff;
        padding: 0 0;
    }

    &__cards {
        .ant-divider-horizontal {
            margin: 5px
        }
    }

}