@import '../../../scss/index.scss';

.materials {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;

      .ant-btn {
        margin-right: 10px;
      }
    }
    &-switch {
      display: flex;
      align-items: center;

      > button {
        margin-right: 20px;
      }
    }
  }

  &__list {
    color: $font-light;

    &__date {
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: $primary-color;
      margin-right: 20px;
      border-radius: 5px;
      text-transform: capitalize;
      padding: 5px 15px;
    }
    .ant-btn-primary .ant-list-item-meta-title {
      font-size: 16px;
    }
  }
}
