@import '../../../scss/index.scss';

.movements {
  .movement {
    color: $font-light;

    &__date {
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: $primary-color;
      margin-right: 20px;
      border-radius: 5px;
      text-transform: capitalize;
      padding: 5px 15px;
    }
    .ant-btn-primary .ant-list-item-meta-title {
      font-size: 16px;
      &:hover {
      }
    }
  }
}
