@import './variables.scss';

.ant-btn-link {
  color: $second-button-color;
  border: none;
  &:hover,
  &:active,
  &:focus {
    color: $second-button-color-hover;
  }
}
