@import '../../../scss/variables.scss';
.list-users {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-switch {
      align-items: center;
      width: auto;

      @media (max-width: $media-break-point-up-sm) {
        width: 20%;
      }
      > button {
        margin-right: 20px;
        background-color: $second-button-color;
        border-color: $second-button-color;
        color: #fff;
      }
    }
    &-search {
      margin-left: 2%;
      @media (max-width: $media-break-point-up-sm) {
        margin-left: 5%;
      }
      flex: 1;
    }
  }

  .users {
    background-color: #fff;
    padding: 10px 20px;

    .ant-list-item-meta {
      display: flex;
      align-items: center;

    }
    .ant-list-item-meta-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  }
}
