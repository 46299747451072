@import '../../scss/index.scss';

.configurations {
  &__list {
    color: $font-light;

    .ant-btn-primary .ant-list-item-meta-title {
      font-size: 16px;
    }
  }
}
